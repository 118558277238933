import { SubmitInputType } from '@/common/components/RichMessageEditor';
import { IComment } from '@/modules/workOrders/types/comment';
import { formatDateToYYYYMD } from '@/utils/date/date';
import useTranslation from '@/utils/i18n/useTranslation';
import { Box, Center, Flex } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import CommentListItem from './CommentListItem';

type GroupedComments = {
  key: string;
  comments: IComment[];
};

const groupCommentsByDate = (comments: IComment[]): GroupedComments[] => {
  const groupedComments = comments.reduce((acc, comment) => {
    const dateKey = formatDateToYYYYMD(comment.createdAt);
    const index = acc.findIndex((group) => group.key === dateKey);
    if (index === -1) {
      acc.push({ key: dateKey, comments: [comment] });
    } else {
      acc[index].comments.push(comment);
    }
    return acc;
  }, [] as GroupedComments[]);
  return groupedComments;
};

type CommentListType = {
  comments: IComment[];
  onCommentsItemEdit: (commentId: number, input: SubmitInputType) => Promise<void>;
  onCommentsItemReply: (input: SubmitInputType, replyCommentId?: number) => Promise<void>;
  onCommentsItemDelete: (commentId: number) => Promise<void>;
  onCommentsItemGetLink: (commentId: number) => void;
  onGoToCommentClick: (commentId: number) => void;
  setUserAvatarBgColor?: (userId: string, color: string) => void;
  targetCommentId?: number | null;
  highlightCommentId?: number | null;
  isEditDisabled?: boolean;
};

const CommentList: FC<CommentListType> = (props: CommentListType) => {
  const {
    comments,
    onCommentsItemEdit,
    onCommentsItemDelete,
    onCommentsItemGetLink,
    onCommentsItemReply,
    onGoToCommentClick,
    setUserAvatarBgColor,
    targetCommentId,
    highlightCommentId,
    isEditDisabled,
  } = props;
  const { t } = useTranslation();
  const commentGroupedByDate = useMemo(() => groupCommentsByDate(comments), [comments]);

  if (commentGroupedByDate.length === 0)
    return (
      <Center color='gray.400' mb='4'>
        {t('comment.no-comments')}
      </Center>
    );

  return (
    <Box>
      {commentGroupedByDate.map((item) => {
        return (
          <Box key={item.key}>
            <Flex py='2' alignItems='center'>
              <Box flex='1' height='1px' background='gray.300'></Box>
              <Box mx='5' color='gray.800' fontSize='sm'>
                {item.key}
              </Box>
              <Box flex='1' height='1px' background='gray.300'></Box>
            </Flex>
            {item.comments.map((comment) => {
              return (
                <CommentListItem
                  key={comment.id}
                  comment={comment}
                  onEdit={onCommentsItemEdit}
                  onDelete={onCommentsItemDelete}
                  onReply={onCommentsItemReply}
                  onGetLink={onCommentsItemGetLink}
                  onGoToCommentClick={onGoToCommentClick}
                  setUserAvatarBgColor={setUserAvatarBgColor}
                  isActive={comment.id === targetCommentId}
                  isHighlighted={comment.id === highlightCommentId}
                  isEditDisabled={isEditDisabled}
                />
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default CommentList;
