import RichMessageEditor, { SubmitInputType } from '@/common/components/RichMessageEditor';
import { useApplicationContext } from '@/context/ApplicationContext';
import { IComment } from '@/modules/workOrders/types/comment';
import useTranslation from '@/utils/i18n/useTranslation';
import { Box } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import DisplayCommentHeader from './DisplayCommentHeader';

type CommentListItemType = {
  comment: IComment;
  onEdit: (commentId: number, input: SubmitInputType) => Promise<void>;
  onReply: (input: SubmitInputType, replyCommentId?: number) => Promise<void>;
  onDelete: (commentId: number) => Promise<void>;
  onGetLink: (commentId: number) => void;
  onGoToCommentClick: (commentId: number) => void;
  setUserAvatarBgColor?: (userId: string, color: string) => void;
  isActive?: boolean | null;
  isHighlighted?: boolean | null;
  isEditDisabled?: boolean;
};

const CommentListItem = (props: CommentListItemType) => {
  const {
    comment: { id, comment, commentLexical, createdAt, user, isEdited, replyComment, attachments },
    onEdit,
    onDelete,
    onGetLink,
    onReply,
    onGoToCommentClick,
    setUserAvatarBgColor,
    isActive,
    isHighlighted,
    isEditDisabled,
  } = props;
  const { me } = useApplicationContext();
  const [isCommentEditing, setCommentEditing] = useState<boolean>(false);
  const [isReplying, setReplying] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(true);
  const { t } = useTranslation();

  const onCommentEditSubmit = async (input: SubmitInputType) => {
    await onEdit(id, input);
    setCommentEditing(false);
    setShowMenu(true);
  };

  const onCommentEditClick = () => {
    setCommentEditing(true);
    setShowMenu(false);
  };

  const onCommentEditCancel = () => {
    setCommentEditing(false);
    setShowMenu(true);
  };

  const onCommentDeleteClick = async () => {
    await onDelete(id);
  };

  const onCommentReplyClick = () => {
    setReplying(true);
    setShowMenu(false);
  };

  const onCommentReplySubmit = async (input: SubmitInputType) => {
    if (input.text !== '' || input.attachments.length > 0) {
      if (onReply) await onReply(input, id);
    }
    setReplying(false);
    setShowMenu(true);
  };
  const onCommentReplyCancel = () => {
    setReplying(false);
    setShowMenu(true);
  };

  const onLinkGetClick = useCallback(() => {
    onGetLink(id);
  }, [id, onGetLink]);

  const handleSetAvatarBgColor = useCallback(
    (color: string) => {
      if (setUserAvatarBgColor) setUserAvatarBgColor(user.id, color);
    },
    [setUserAvatarBgColor, user.id]
  );

  return (
    <Box
      pos='relative'
      border='1px'
      borderRadius='2'
      padding='2'
      borderColor={isReplying ? 'gray.100' : isActive ? 'gray.200' : 'transparent'}
      id={`comment-id-${id}`}
      backgroundColor={isHighlighted ? 'orange.100' : isActive ? 'gray.50' : 'transparent'}
    >
      <DisplayCommentHeader
        id={id}
        createdAt={createdAt}
        isCurrentUser={me?.id === user.id}
        isEdited={isEdited}
        showMenu={showMenu}
        userName={user.name}
        onLinkGetClick={onLinkGetClick}
        onDelete={onCommentDeleteClick}
        onReply={onCommentReplyClick}
        onEdit={onCommentEditClick}
        setAvatarBgColor={handleSetAvatarBgColor}
        isEditDisabled={isEditDisabled}
      />
      {!isCommentEditing && (
        <RichMessageEditor
          value={comment}
          valueLexical={commentLexical}
          attachments={attachments ?? []}
          submit={onCommentEditSubmit}
          mode='display'
          onCancel={onCommentEditCancel}
          replyComment={replyComment ?? undefined}
          onGoToCommentClick={onGoToCommentClick}
        />
      )}
      {isCommentEditing && (
        <RichMessageEditor
          value={comment}
          valueLexical={commentLexical}
          attachments={attachments ?? []}
          submit={onCommentEditSubmit}
          mode='edit'
          onCancel={onCommentEditCancel}
        />
      )}
      {isReplying && (
        <Box mt={2}>
          <RichMessageEditor
            placeholder={t('comment.enter-your-reply')}
            submit={onCommentReplySubmit}
            mode='reply'
            onCancel={onCommentReplyCancel}
          />
        </Box>
      )}
    </Box>
  );
};

export default CommentListItem;
